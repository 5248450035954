import React, { Fragment, useEffect, useState } from 'react';
import { GetProducts, getByMkt, getItemsPerCategoryDispatch } from '../../../server/Product'
import { getFilters } from '../../../server/Filter';
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const ProductEdit = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { filterList, product, role, products, stationId, parameters: { p_unit_measure }} = useSelector(state => ({
		filterList: state.displayProduct.filterList,
		product: state.selectedProduct.product,
		products: state.selectedProduct.products,
		role: state.user.role,
		stationId: state.user.stationId,
		parameters: state.parameters
	}));

	useEffect(() => {
		if (role) {
			dispatch(getByMkt(id, stationId))
			if (!products?.length) {
				dispatch(GetProducts());
			}
			if (filterList) {
				dispatch(getFilters())
			}
			setTimeout(() => window.scrollTo(0, 0), 200);
		}
	}, [role, id])
	

	return product ?
		<Fragment>
			<h2 
			onClick={() => {
				if (product.Category.length === 3) {
				navigate(`/category/${product.Category[0].Id}/${product.Category[1].Id}/${product.Category[2].Id}`);
				} else if (product.Category.length === 2) {
				navigate(`/category/${product.Category[0].Id}/${product.Category[1].Id}`);
				} else if (product.Category.length === 1) {
				navigate(`/category/${product.Category[0].Id}`);
				} else {
				console.warn('No categories available for this product.');
				}
			}} 
			style={{ cursor: "pointer"}}
			>
			↪ {product.ProductName} | {product.WeightQuantity} {p_unit_measure?.filter(measure => measure.Id === product.UnitMeasureId)[0].Name} | {filterList[0]?.Items?.filter(brand => brand.Id === product.BrandId)[0].Name} | {product.Barcode} | {product.Mkt}
			</h2>
			<div className='tab-header'>
				<NavLink to="information" className="tab">פרטי מוצר</NavLink>
				<NavLink to="logistic" className="tab">לוגיסטיקה </NavLink>
				<NavLink to="img" className="tab">תמונות</NavLink>
				<NavLink to="comper" className="tab">מחירים</NavLink>
				<NavLink to="inventory" className="tab">מלאי </NavLink>
				<NavLink to="inventory-update" className="tab">העברת מלאי </NavLink>
			</div>
			<div className="outlet">
				<Outlet />
			</div>

		</Fragment>
		: null
}


export default ProductEdit;